import React, { useState, useEffect } from 'react';
import { usePaginatedTitlesForTag } from "./Hooks/usePaginatedTitlesForTag"
import { Link, useParams } from 'react-router-dom';
import useTag from './Hooks/useTag';

export default function TitlesForTag() {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedTitles()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const resultsPerPage = 54;

    const { tagId } = useParams();
    const tag = useTag(tagId);
    const titles = usePaginatedTitlesForTag(tag.id, referenceItem, include, resultsPerPage);

    // SETUP
    const currentLastVisibleItem = (titles !== undefined) ? titles.docs[titles.size - 1] : null;

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (titles !== undefined && titles.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (titles !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, titles]);

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, titles.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    return (
        <>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h1 className="my-0 text-2xl font-bold text-gray-900 sm:text-3xl">Browse titles tagged {tag.title}</h1>

                    {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Recently loaded titles are shown below:</p>*/}
                    {/*formLoading === true && <LoadingBanner />*/}
                    {/*errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />*/}
                </div>
            </div>

            {/* No titles; fallback, shouldn't ever happen */}
            {titles.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No titles match this tag.</p>
                </div>
            </div>}

            {/* Titles list */}
            {titles.docs.length > 0 && <>
                {/* The list */}
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    {titles.size > 0 && <ul className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8">
                        {titles.docs.map((item, idx) => (
                            <Link key={`l_${idx}`} to={`/app/title/${item.id}`}>
                                <li key={`li_${idx}`} className="relative">
                                    <img src={item.data().thumbnail} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                                    <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{item.data().title}</p>
                                </li>
                            </Link>
                        ))}
                    </ul>}
                </div>

                {/* Button actions */}
                <nav className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{firstItemStack.length === 0 ? titles.docs.length : (firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button type='button' onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button type='button' onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button type='button' onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            </>}
        </>
    )
}