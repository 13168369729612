import React, { Suspense } from 'react';
import './index.css';
import LandingPage from './Landing/Index';
import PrivacyPage from './Landing/PrivacyPage';
import PortalPage from './Portal/Support/Index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingProgress from "./LoadingProgress.js";
import { FirebaseAppProvider } from 'reactfire';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);

const firebaseConfig = {
    apiKey: "AIzaSyD6RfNtDIEKhjXpzeWnBW_xUZjgFkILSeI",
    authDomain: "findflix-1e61f.firebaseapp.com",
    projectId: "findflix-1e61f",
    storageBucket: "findflix-1e61f.appspot.com",
    messagingSenderId: "21082022876",
    appId: "1:21082022876:web:4f6232412df3097d6da519",
    measurementId: "G-Z86DHZV8TV"
};

root.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingProgress />}>
            <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
                <Router>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/app/*" element={<PortalPage />} />
                    </Routes>
                </Router>
            </FirebaseAppProvider>
        </Suspense>
    </React.StrictMode>
);