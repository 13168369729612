import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="bg-white" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                <div className="py-6">
                    <Link to="/privacy" className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        Privacy Policy
                    </Link>
                </div>
            </nav>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-2 sm:pt-2 lg:px-2 lg:pt-2">
                <div className="mt-4 border-t border-gray-900/10 pt-8 sm:mt-4 md:flex md:items-center md:justify-between lg:mt-4">
                    <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
                        &copy; 2023 FindFlix Holdings Pty Ltd. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}