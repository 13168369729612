import { Link } from 'react-router-dom';
import { useGenres } from './Hooks/useGenres';

export default function BrowseGenresPage({ authUser }) {
    const genres = useGenres()

    return (
        <>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h1 className="my-0 text-2xl font-bold text-gray-900 sm:text-3xl">Browse Genres</h1>

                    {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Recently loaded titles are shown below:</p>*/}
                    {/*formLoading === true && <LoadingBanner />*/}
                    {/*errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />*/}
                </div>
            </div>

            {genres.length > 0 &&
                <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {genres.map((item) => (
                        <Link to={`./${item}`}>
                            <li className="col-span-1 bg-blue-500 rounded-lg shadow ">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 truncate">
                                        <div className="flex justify-center space-x-3">
                                            <span className="text-white text-md font-medium truncate">{item}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>
            }
        </>
    )
}