import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

// https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks

export default function TitleSearchResults({ authUser, searchTerm }) {

    // STATE
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    // HOOKS

    // HELPERS

    useEffect(() => {
        algoliaSearch(searchTerm);
    }, [searchTerm]);

    const algoliaSearch = async (term) => {
        if (term === undefined || term === null || term.trim() === '') {
            return
        }

        setLoading(true);
        const body = { params: `query=${term}&hitsPerPage=12` };

        fetch('https://LBI3TESJE2-dsn.algolia.net/1/indexes/titles/query', {
            method: 'POST',
            headers: {
                "X-Algolia-API-Key": "78ce41615dfdb801a0e879afa6be0554",
                "X-Algolia-Application-Id": "LBI3TESJE2",
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.error) {
                    window.alert("Error: " + data.message);
                } else {
                    if (data.hits) {
                        setResults(data.hits);
                    } else {
                        setResults([]);
                    }
                }
            });
    }

    return (
        <>
            {/* Loading spinner */}
            {loading === true && <div className="h-screen flex items-center justify-center">
                <img src="/images/loading.gif" width="90" height="90" alt="" className="mb-4" />
            </div>}

            {/* Empty state */}
            {(loading === false && results.length === 0) && <div className="h-screen flex items-center justify-center">
                <div className="w-96 bg-gray-100 shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">No titles found</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>No titles match your current search term; update your search term and try again.</p>
                        </div>
                    </div>
                </div>
            </div>}

            {/* Results grid */}
            {(loading === false && results.length > 0) && <ul className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {results.map((result) => (
                    <Link to={`/app/title/${result.objectID}`}>
                        <li key={result.objectID} className="relative">
                            <img src={result.thumbnail} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{result.title}</p>
                        </li>
                    </Link>
                ))}
            </ul>}
        </>
    )
}

// [
//     {
//         "path": "titles/oDICKJSBRsmSLeYFvdUU",
//         "title": "Adera",
//         "synopsis": "The story of an Ethiopian woman's plight in South Africa as she struggles to earn enough money to support and return to her children in Ethiopia.",
//         "thumbnail": "https://cdn.findflix.com/thumbs/oDICKJSBRsmSLeYFvdUU.png",
//         "release_date": 1233439200000,
//         "lastmodified": {
//             "_operation": "IncrementSet",
//             "value": 1699258594467
//         },
//         "objectID": "oDICKJSBRsmSLeYFvdUU",
//         "_highlightResult": {
//             "title": {
//                 "value": "<em>Adera</em>",
//                 "matchLevel": "full",
//                 "fullyHighlighted": true,
//                 "matchedWords": [
//                     "adera"
//                 ]
//             },
//             "synopsis": {
//                 "value": "The story of an Ethiopian woman's plight in South Africa as she struggles to earn enough money to support and return to her children in Ethiopia.",
//                 "matchLevel": "none",
//                 "matchedWords": []
//             }
//         }
//     }
// ]