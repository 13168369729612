import React from 'react';
import { useUser, useSigninCheck, useIdTokenResult } from 'reactfire';
import PortalHomePage from './PortalHomePage.js';
import SignInPage from './SignInPage.js';

export default function AuthCheck() {
    const { status, data: signInCheckResult } = useSigninCheck();
    const { data: authUser } = useUser();
    const { data: userIdToken } = useIdTokenResult(authUser);

    if (status === 'loading') {
        return "LOADING...";
    }

    const hasPortalClaim = () => {
        return (userIdToken && userIdToken.claims && userIdToken.claims.portal && userIdToken.claims.portal === true);
    }

    const isSignedIn = () => {
        return (signInCheckResult.signedIn === true && authUser != null && authUser.uid != null);
    }

    // return...
    if (isSignedIn() && hasPortalClaim()) {
        return <PortalHomePage authUser={authUser} />;
    } else {
        return <SignInPage />;
    }
}