import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

export default function useTitle(tagId) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'tags', tagId);

    // eslint-disable-next-line no-unused-vars
    const { status, data: tag } = useFirestoreDocData(documentRef, { idField: "id" });

    if (status !== 'success') {
        return undefined;
    }

    return tag;
}