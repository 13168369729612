import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedTitlesForTag(tagId, startItem, include, rpp) {
    const firestore = useFirestore();
    const titlesCollection = collection(firestore, 'titles');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(titlesCollection, where('tags', 'array-contains', tagId), orderBy('created', 'desc'), limit(rpp))
    } else {
        activeQuery = (include) ? 
            query(titlesCollection, where('tags', 'array-contains', tagId), orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : 
            query(titlesCollection, where('tags', 'array-contains', tagId), orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: titles } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return titles;
}
