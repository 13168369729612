import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useGenres() {
    const firestore = useFirestore();
    const genresCollection = collection(firestore, 'genres');
    var activeQuery = query(genresCollection, orderBy('title', 'asc'))
    const { status, data: genreDocs } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    var genres = [];
    genreDocs.forEach(doc => {
        genres.push(doc.data().title);
    });

    return genres;
}
