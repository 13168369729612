import { doc } from 'firebase/firestore';
import { useFirestoreDocDataOnce, useFirestore } from 'reactfire';

// hook to get fname, lname from firestore userinfo
export default function useUserInfo(userId) {
    // a parent component contains a `FirebaseAppProvider`
    const docRef = doc(useFirestore(), 'userinfo', (userId !== undefined) ? userId : 'xxx'); // xxx is to handle undefined better
    const { status, data: userinfo } = useFirestoreDocDataOnce(docRef);

    if (userId === undefined) {
        return undefined;
    }

    if (status === 'loading') {
        return undefined;
    }
    
    return userinfo;
}