export default function LogoCloud() {
    return (
        <div className="bg-gray-900 mt-8 py-12 sm:py-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
                    <div className="mx-auto w-full max-w-xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-white">Featuring on the most demanded platforms</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            FindFlix places content it represents rights for onto a plethora of VOD platforms as well as its 
                            own YouTube channels, each serving a specific genre niche and collectively in a multi channel network.
                        </p>
                        {/*<div className="mt-8 flex items-center gap-x-6">
                            <a href="#" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Create account</a>
                            <a href="#" className="text-sm font-semibold text-white">Contact us <span aria-hidden="true">&rarr;</span></a>
                        </div>*/}
                    </div>
                    <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Netflix.svg" alt=""
                            width={126} height={34} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Youtube.svg" alt=""
                            width={153} height={34} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Peacock.svg" alt=""
                            width={109} height={35} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Disney.svg" alt=""
                            width={109} height={60} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Max.svg" alt=""
                            width={110} height={30} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Amazon.svg" alt=""
                            width={153} height={47} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Hulu.svg" alt=""
                            width={104} height={34} />
                        <img className="max-h-12 w-full object-contain object-left"
                            src="/images/Logo_Apple.svg" alt=""
                            width={99} height={38} />
                    </div>
                </div>
            </div>
        </div>
    )
}