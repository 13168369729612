const features = [
    {
        name: 'Marketing',
        description:
            'Our strategic marketing know-how allows content owners to maximise visibility and reach of their content.',
        icon: "Icon_2.svg",
    },
    {
        name: 'Distribution',
        description:
            'FindFlix facilitates distribution of original content to reach its audiences on major streaming platforms.',
        icon: "Icon_4.svg",
    },
    {
        name: 'Data',
        description:
            'Analytics and insights ensure data-driven content creation, distribution, and marketing strategies.',
        icon: "Icon_3.svg",
    },
]

export default function HeadlineFragment() {

    return (
        <div className="mx-auto max-w-2xl py-6 sm:py-12 lg:py-12">
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    What We Do
                </h1>
            </div>

            <div className="mx-auto mt-6 max-w-2xl sm:mt-20 lg:mt-12 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                    {features.map((feature) => (
                        <div key={feature.name} className="relative pl-16">
                            <dt className="text-base font-semibold leading-7 text-gray-900">
                                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                    <img src={`/images/${feature.icon}`} className="h-8 w-8 text-white" alt="" aria-hidden="true" />
                                </div>
                                {feature.name}
                            </dt>
                            <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}