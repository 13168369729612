import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useTags() {
    const firestore = useFirestore();
    const tagsCollection = collection(firestore, 'tags');
    var activeQuery = query(tagsCollection, orderBy('title', 'asc'))
    const { status, data: tagsDocs } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    var tags = [];
    tagsDocs.forEach(doc => {
        tags.push( { guid: doc.id, title: doc.data().title } );
    });

    return tags;
}
