import { useState } from 'react'
import SideNavigation, { SideNavigationCompact } from './SideNavigation'
import { Routes, Route } from "react-router-dom";
import SettingsPage from '../SettingsPage';
import ReportsPage from '../ReportsPage';
import FeedbackPage from '../FeedbackPage';
import TitleDetailPage from '../TitleDetailPage';
import TitleAddPage from '../TitleAddPage';
import TitleEditPage from '../TitleEditPage';
import DashboardPage from '../DashboardPage';
import ProfilePage from '../ProfilePage';

import BrowseTitlesPage from '../BrowseTitlesPage';
import BrowseGenresPage from '../BrowseGenresPage';
import BrowseTagsPage from '../BrowseTagsPage';

import TitlesForGenre from '../TitlesForGenre';
import TitlesForTag from '../TitlesForTag';

export default function PortalHomePage( { authUser } ) {
    // STATE
    const [sidebarOpen, setSidebarOpen] = useState(false); // maintained at this level but passed to sidebar and page header

    return (

        <div>
            {/* Static sidebar for compact mode */}
            <SideNavigationCompact open={sidebarOpen} setOpen={setSidebarOpen} />

            {/* Static sidebar for desktop */}
            <SideNavigation />

            <div className="lg:pl-72">

                <main className="pt-2 pb-10">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <Routes>
                            <Route path="*" element={<DashboardPage authUser={authUser} setOpen={setSidebarOpen} />} />
                            <Route path="title/add" element={<TitleAddPage authUser={authUser} />} />
                            <Route path="title/:titleId" element={<TitleDetailPage authUser={authUser} />} />
                            <Route path="title/edit/:titleId" element={<TitleEditPage authUser={authUser} />} />
                            <Route path="reports/*" element={<ReportsPage authUser={authUser} />} />
                            <Route path="feedback/*" element={<FeedbackPage authUser={authUser} />} />
                            <Route path="browse/*" element={<BrowseTitlesPage authUser={authUser} />} />
                            <Route path="browse/genres" element={<BrowseGenresPage authUser={authUser} />} />
                            <Route path="browse/genres/:genreId" element={<TitlesForGenre authUser={authUser} />} />
                            <Route path="browse/tags" element={<BrowseTagsPage authUser={authUser} />} />
                            <Route path="browse/tags/:tagId" element={<TitlesForTag authUser={authUser} />} />
                            <Route path="profile/*" element={<ProfilePage authUser={authUser} />} />
                            <Route path="settings/*" element={<SettingsPage authUser={authUser} />} />
                        </Routes>
                    </div>
                </main>
            </div>
        </div>

    )
}