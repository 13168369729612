import './App.css';
import PageHeader from './Components/PageHeader';
import Footer from './Footer';

export default function PrivacyPage() {

    return (
        <div className="bg-white">
            <PageHeader title="Privacy Policy" subtitle="This Privacy Policy describes how FindFlix collects, uses, and discloses information, and what choices you have with respect to the information." />

            <div className="bg-white px-6 py-3 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h3 className='py-4'><b>What Information We Collect and Process</b></h3>

                    <p>Privacy by design. FindFlix is not in the business of gathering and gathering users' personal
                        information and it is not in out interests and sell data to advertisers or to using it internally.</p>

                    <p>The sections below are aimed at providing more information about how FindFlix processes different types
                        of data.</p>

                    <p>Location information. FindFlix neither requests permission to access your device's location from iOS,
                        nor does it attempt to calculate your device's exact or approximate location. FindFlix might use
                        your device's region settings ("locale" and "language") in order to customise its user interface and when
                        using the Feedback screen.</p>

                    <p>Log data. FindFlix may generate log information internally as it is being used, which is recorded in
                        log files on your device. Such log data may include the IP address, the section of the app being used, the device
                        details and settings, the date and time the services were used, crash data, and language preferences. This data
                        remains on your device and cannot be accessed unless you choose to send it, for example when requesting
                        support from the "Contact us" screen. When dooing so, the fields and data that are being sent with your
                        support request appear on the screen for you to review.</p>

                    <p>Information you choose to provide. If you contact us, for example by using the "Contact Us" screen in the app or
                        via our web site, then we would need to use the information you provide such as your name and email address in
                        order to respond to your feedback, comments and questions.</p>

                    <p>Aggregate use data. FindFlix sends anonymous usage information, which cannot be tied to you in any way, but
                        which provides insights about how the app is used which is useful to help improve FindFlix, such as: • how often
                        the app is opened by users (to understand how well the app is doing with users); • how big user's libraries are (to know
                        if assumptions and decisions about the Favourites/library user interface are appropriate); • which settings are accessed
                        and changed (to understand customisation of the app); which devices users are using (to understand screen sizes which
                        affects user interface design, and to understand iOS versions which affects decisions about supported iOS version);
                        the region and language setting (to better target which languages and regions to prioritise content and translation
                        work).</p>

                    <p>The above aggregate use data is incorporated using a service called TelemetryDeck. Please see their privacy policy
                        at <a href="https://telemetrydeck.com/privacy">https://telemetrydeck.com/privacy</a> to understand how they process data,
                        and why we believe it is a good balance between understanding how FindFlix is used, vs the need to not save
                        any data that can be used to identify specific users' identities.</p>

                    <h3 className='py-4'><b>Data Retention</b></h3>

                    <p>FindFlix does not collect personally identifying information and therefore does not retain data about users
                        which may require retention or deletion.</p>

                    <h3 className='py-4'><b>Requesting Data Deletion</b></h3>

                    <p>The exception to the above is if you have contacted us previously via Support, in which case you have the right to
                        request at any time that we delete from our systems any record of you having contact us. Due to the nature of support
                        requests this will be a manual process. Send an email to <a href="mailto:privacy@findflix.com">privacy@findflix.com</a> to
                        request that we delete your information.</p>

                    <h3 className='py-4'><b>How We Share And Disclose Information</b></h3>

                    <p>We do not share or disclose information since it is our policy and principle to not gather personal information
                        at all.</p>

                    <h3 className='py-4'><b>Changes To This Privacy Policy</b></h3>

                    <p>This Privacy Policy may change from time to time if necessitated by changing laws, regulations, or industry standards;
                        or we may make changes to our business. We will post the changes to this page. If we make changes that materially alter
                        your privacy rights, FindFlix will provide clear, unambiguous notice through the app interface. If you disagree with
                        the changes to this privacy policy, you should uninstall the app and cease to use it.</p>

                    <h3 className='py-4'><b>International Data Transfers</b></h3>

                    <p>When contacting FindFlix Holdings for support, your personal data (name and email address) may be transferred
                        to a country other than the one in which you live.</p>

                    <h3 className='py-4'><b>Data Protection Officer</b></h3>

                    <p>To communicate with our Data Protection Officer, please email <a href="mailto:privacy@findflix.com">privacy@findflix.com</a>.</p>

                    <h3 className='py-4'><b>Your Rights</b></h3>

                    <p>Individuals located in certain countries, including the European Economic Area, have certain statutory rights in
                        relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access
                        to information, as well as to seek to update, delete or correct this information. You can usually do this using the
                        settings and tools provided in your services account. If you cannot use the settings and tools, contact customer support
                        for additional access and assistance.</p>

                    <p>To the extent that FindFlix Holdings' processing of your personal data is subject to the General Data Protection
                        Regulation (GDPR), FindFlix Holdings relies on its legitimate interests, described above, to process your data.</p>
                </div>
            </div>




            <Footer />
        </div>
    )
}