import React, { useState, useEffect } from 'react';
import { useGenres } from '../Hooks/useGenres';

// deconstruct props.initialSelection
export default function GenreChooser({ initialSelection, form }) {

    // HOOKS
    const genresArray = useGenres();

    // STATE
    const [selectedCheckboxes, setSelectedCheckboxes] = useState((initialSelection !== undefined && initialSelection !== null && Array.isArray(initialSelection)) ? initialSelection : []);

    // INTERNAL

    function checkboxChanged(e) {
        const isChecked = e.target.checked
        const value = e.target.value

        if (isChecked === true) {
            const temp = [...selectedCheckboxes, value];
            setSelectedCheckboxes(temp);
        } else {
            var temp = selectedCheckboxes.filter(item => item !== value);
            setSelectedCheckboxes(temp);
        }
    }

    // lifts state up to edit/create title page
    // can't print debug output of setSelectedCheckboxes immediately since setState is asyncronous
    // NB don't trigger validation on touched per https://github.com/jaredpalmer/formik/issues/2059
    useEffect(() => {
        // console.log("[GenreChooser] useEffect() " + JSON.stringify(selectedCheckboxes));
        form.setFieldValue('genres', selectedCheckboxes);
        form.setFieldTouched('genres', true, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCheckboxes, form.setFieldTouched, form.setFieldValue]);

    // HELPERS

    // note: wanted to use this to strike through genres marked as inactive
    //       but useGenres only fetches the field not the active flag.
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // EMPTY STATE
    if (genresArray.length === 0) {
        return (
            <div className="mt-5 sm:col-span-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <p className="text-sm text-yellow-700">No genres are currently loaded.</p>
                    </div>
                </div>
            </div>
        );
    }

    // GO
    return (
        <div>
            <ul className="grid grid-cols-1 gap-2 sm:gap-2 sm:grid-cols-2 lg:grid-cols-4">
                {genresArray.map((genre, index) => (
                    <li key={`li_${index}`} className="col-span-1 flex">
                        <div className="flex-shrink-0 flex items-center justify-center w-10">
                            <input id={index} type="checkbox" onChange={(e) => checkboxChanged(e)} name="genres" value={genre} defaultChecked={initialSelection.includes(genre)} className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded" />
                        </div>
                        <div className="flex-1 flex items-center justify-between bg-white truncate">
                            <div className="flex items-center justify-center h-6">
                                <label htmlFor={index} className={classNames("text-sm text-gray-700")}>{genre}</label>
                            </div>
                        </div>
                    </li>
                ))}

                {/* Add any genres that might be on the title but aren't in the lookup */}
                {initialSelection.filter((g) => { return !genresArray.includes(g) }).map((genre, index) => (
                    <li key={`lx_${index}`} className="col-span-1 flex">
                        <div className="flex-shrink-0 flex items-center justify-center w-10">
                            <input id={index} type="checkbox" onChange={(e) => checkboxChanged(e)} name="genres" value={genre} defaultChecked={initialSelection.includes(genre)} className="focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded" />
                        </div>
                        <div className="flex-1 flex items-center justify-between bg-white truncate">
                            <div className="flex items-center justify-center h-6">
                                <label htmlFor={index} className="text-sm text-gray-700">{genre}</label>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}