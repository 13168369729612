import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import ActorAutocomplete from './ActorAutocomplete'
import { Formik } from 'formik';
import * as Yup from "yup";

export default function AddActorModal({ isOpen, handler }) {
    const [open, setOpen] = useState(isOpen)
    const [selectedActor, setSelectedActor] = useState(undefined);

    // eslint-disable-next-line
    const [showDebugInfo, setShowDebugInfo] = useState(false)

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    function closeDialog() {
        setOpen(false);

        if (handler) {
            handler(undefined);
        }
    }

    // from ActorAutocomplete
    const handleActorChange = async (newVal, setFieldValue, setFieldTouched) => {
        // do nothing if nothing is returned
        if (newVal === undefined || !newVal.id || !newVal.name) {
            return;
        }

        if (newVal && newVal.id && newVal.name) {
            await setFieldValue('actor', { guid: newVal.id, name: newVal.name });
            await setFieldTouched('actor', true);

            // keep our own copy outside the form to display to the user
            await setSelectedActor({ guid: newVal.id, name: newVal.name });
        }
    }

    const initialValues = {
        actor: undefined,
        role: undefined,
    };

    const validationSchema = Yup.object({
        actor: Yup.object({
            guid: Yup.string("Enter a serial number").defined(),
            name: Yup.string("Enter a serial number").defined(),
        }).required(),
        role: Yup.string("Enter role").optional(),
    });

    async function handleSubmit(form) {
        // will receive:  {"role":"Main star","actor":{"guid":"wpjc8t3wRlPdP3NRigtS","name":"Christopher Nolan"}}

        // will send:
        if (form && form.actor && form.role && form.actor.guid && form.actor.name) {
            handler({ guid: form.actor.guid, name: form.actor.name, role: form.role });
        } else if (form && form.actor && form.actor.guid && form.actor.name) {
            handler({ guid: form.actor.guid, name: form.actor.name });
        }

        // finally, close
        setOpen(false);
    }

    return (
        <>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={() => { /* no-op */ }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                {/* Note to self: Formik must be within Transition.Child or won't submit */}
                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount={true}>
                                    {({ handleSubmit, handleChange, handleBlur, isValid, isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                                        <form onSubmit={handleSubmit} className="">
                                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => closeDialog()} >
                                                    <span className="sr-only">Close</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <div className="sm:flex sm:items-start">
                                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <UserCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                                                </div>
                                                <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                        Insert Actor
                                                    </Dialog.Title>

                                                    <p className='text-gray-900'>Enter details below.</p>
                                                    {showDebugInfo && JSON.stringify(errors)}
                                                    <div className="mt-2">
                                                        <div className="">
                                                            <label htmlFor="actor" className="block text-sm font-medium text-gray-700">
                                                                {!selectedActor && <span className="text-sm font-medium text-gray-700">Choose an actor:</span>}
                                                                {selectedActor && <><span className="text-sm font-medium text-gray-700">Selected: </span><span className="text-sm font-medium text-green-700">{selectedActor.name}</span></>}
                                                            </label>
                                                            <ActorAutocomplete handler={handleActorChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} />
                                                        </div>

                                                        <div className='mt-3'>
                                                            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                                                Playing role:
                                                            </label>
                                                            <input type="text" name="role" id="role" placeholder='Optional' autoComplete="off" defaultValue={initialValues.role} onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button type="submit" disabled={isSubmitting || !isValid} className="disabled:opacity-40 disabled:bg-indigo-1000 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-800 text-base font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm" >
                                                    Insert
                                                </button>
                                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => closeDialog()} >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik >
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

        </>
    )
}