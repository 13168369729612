import { Link } from 'react-router-dom';

export default function BrowseTitlesPage({ authUser }) {
    return (
        <>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h1 className="my-0 text-2xl font-bold text-gray-900 sm:text-3xl">Browse Titles</h1>

                    {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Recently loaded titles are shown below:</p>*/}
                    {/*formLoading === true && <LoadingBanner />*/}
                    {/*errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />*/}
                </div>
            </div>

            <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    <Link to="./genres">
                        <li className="col-span-1 bg-blue-900 rounded-lg shadow ">
                            <div className="w-full flex items-center justify-between p-6 space-x-6">
                                <div className="flex-1 truncate">
                                    <div className="flex justify-center space-x-3">
                                        <span className="text-white text-md font-medium truncate">By Genre</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </Link>

                    <Link to="./tags">
                        <li className="col-span-1 bg-blue-900 rounded-lg shadow">
                            <div className="w-full flex items-center justify-between p-6 space-x-6">
                                <div className="flex-1 truncate">
                                    <div className="flex justify-center space-x-3">
                                        <span className="text-white text-md font-medium truncate">By Tag</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </Link>
                </ul>
        </>
    )
}