import { useParams } from 'react-router-dom';
import useTitle from "./Hooks/useTitle";
import { useTags } from './Hooks/useTags';
import { formatDate, formatDateShort } from './Functions/dates.js';
import useUserInfo from './Hooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import { countryName } from './Components/CountryChooser';
import { languageName } from './Components/LanguageChooser';

export default function TitleDetailPage({ authUser }) {
    const navigate = useNavigate();
    const { titleId } = useParams();
    const tagsArray = useTags();
    const title = useTitle(titleId); // firestore document snapshot
    const creator = useUserInfo(title.creator); // ready to use JS Object
    const updator = useUserInfo(title.updator); // ready to use JS Object

    const formatChannel = (key) => {
        switch (key) {
            case 1:
                return "Brownphlix";
            default:
                return "Not set";
        }
    }

    const gotoEdit = () => {
        navigate(`/app/title/edit/${titleId}`);
    }

    return (
        <>
            <div className="space-y-12 sm:space-y-16">
                <div>
                    <div className="sticky top-0 z-40 py-2 bg-white border-b border-gray-300">
                        <div className='sm:flex sm:items-center sm:justify-between'>
                            <h1 className="my-2 text-2xl font-bold text-gray-900 sm:text-3xl">
                                Title Details
                                {title.title !== undefined && `: ${title.title}`}
                            </h1>
                            <div className="mt-3 flex sm:mt-0 sm:ml-4">
                                <button type="button" onClick={gotoEdit} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Display title
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    {title.title !== undefined && title.title}
                                    {title.title === undefined && "Not set"}
                                </label>
                            </div>

                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Sort title
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    {title.title_sort !== undefined && title.title_sort}
                                    {title.title_sort === undefined && "Not set"}
                                </label>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Synopsis
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.synopsis !== undefined && title.synopsis}
                                {title.synopsis === undefined && "Not set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Kind
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.kind !== undefined && title.kind === 1) && <div className='flex inline-block'><img src="/images/Kind_movie.png" alt="" className="pr-1" /> Movie</div>}
                                {(title.kind === undefined || title.kind === 2) && <div className='flex inline-block'><img src="/images/Kind_tv.png" alt="" className="pr-1" /> Series</div>}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Created
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {creator !== undefined && `${creator.fname} ${creator.lname}`}
                                {creator === undefined && "Unknown user"}
                                &nbsp;on&nbsp;
                                {title.created !== undefined && formatDate(title.created.toDate())}
                                {title.created === undefined && "(unknown date)"}
                            </div>

                            {updator !== undefined && <>
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Updated
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    {updator !== undefined && `${updator.fname} ${updator.lname}`}
                                    {updator === undefined && "Unknown user"}
                                    &nbsp;on&nbsp;
                                    {(title.updated !== undefined && title.updated !== null) && formatDate(title.updated.toDate())}
                                    {title.updated === undefined && "(unknown date)"}
                                </div>
                            </>}
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Release date
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.release_date !== undefined && formatDateShort(title.release_date.toDate())}
                                {title.release_date === undefined && "Not set"}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Runtime
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.runtimeHH !== undefined && title.runtimeMM !== undefined && !isNaN(title.runtimeHH) && !isNaN(title.runtimeMM)) && <span>{`${title.runtimeHH}h ${title.runtimeMM}m`}</span>}
                                {(title.runtimeHH === undefined || title.runtimeMM === undefined || isNaN(title.runtimeHH) || isNaN(title.runtimeMM)) && "Not set"}
                                {title.runtime && <span><br /> Deprecated: {title.runtime}</span>}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Rating
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.rating !== undefined && title.rating}
                                {title.rating === undefined && "Not set"}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Number of episodes
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.numEpisodes !== undefined && title.numEpisodes}
                                {title.numEpisodes === undefined && "Not set"}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Director(s)
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.directors !== undefined && Object.values(title.directors).map((director, index) => (
                                    <span key={index}>{director}<br /></span>
                                ))}

                                {title.directors === undefined && "Not set"}
                            </div>

                            {title.kind && (title.kind === 2 || title.kind === "2") && <>
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Showrunner(s)
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    {(title.showrunners !== undefined && title.showrunners.length > 0) && title.showrunners.map((showrunner, index) => (
                                        <span key={index}>{showrunner["name"]}<br /></span>
                                    ))}

                                    {(title.showrunners === undefined || Object.keys(title.showrunners).length === 0) && "Not set"}
                                </div>

                                <label htmlFor="creators" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Creator(s)
                                </label>
                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                    {(title.creators !== undefined && title.creators.length > 0) && title.creators.map((creator, index) => (
                                        <span key={index}>{creator["name"]}<br /></span>
                                    ))}

                                    {(title.creators === undefined || Object.keys(title.creators).length === 0) && "Not set"}
                                </div>
                            </>}

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Producer(s)
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.producers !== undefined && title.producers.length > 0) && title.producers.map((producer, index) => (
                                    <span key={index}>{producer["title"]}: {producer["name"]}<br /></span>
                                ))}

                                {(title.producers === undefined || Object.keys(title.producers).length === 0) && "Not set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Country of origin
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.country && countryName(title.country[0])}
                                {!title.country && "Not set"}
                            </div>

                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Language
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.language && languageName(title.language)}
                                {!title.language && "Not set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Cast
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.cast !== undefined && title.cast.length > 0) && title.cast.map((actor, index) => (
                                    <span key={index}>{actor["name"]}: {actor["role"]}<br /></span>
                                ))}

                                {(title.cast === undefined || Object.keys(title.cast).length === 0) && "Not set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Where to Watch
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.watch !== undefined && title.watch.length > 0) && title.watch.map((watchItem, index) => (
                                    <span key={index}>{watchItem.title} at: <u>{watchItem.url}</u><br /></span>
                                ))}

                                {(title.cast === undefined || Object.keys(title.cast).length === 0) && "Not set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Display on
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {title.channels !== undefined && Object.values(title.channels).map((channel, index) => (
                                    <span key={index}>{formatChannel(channel)}<br /></span>
                                ))}

                                {title.channels === undefined && "Not set"}
                            </div>

                            <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Is featured
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                {(title.featured !== undefined && title.featured === true) && <div className='flex inline-block'><img src="/images/Flag_featured.png" alt="" /> Yes</div>}
                                {(title.featured === undefined || title.featured === false) && <div className='flex inline-block'><img src="/images/Flag_featured_off.png" alt="" /> Nope</div>}
                            </div>

                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Genres
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex flex-rows">
                                {title.genres !== undefined && Object.values(title.genres).map((genre, index) => (
                                    <span key={index} className="mx-1 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                        {genre}
                                    </span>
                                ))}

                                {title.genres === undefined && "Nome set"}
                            </div>

                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Tags
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex flex-rows">
                                {title.tags !== undefined && Object.values(title.tags).map((tag, index) => (
                                    <span key={index} className="mx-1 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                        {tagsArray.find(item => item.guid === tag)?.title || tag}
                                    </span>
                                ))}

                                {title.tags === undefined && "Nome set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                Partner links
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0 flex flex-rows">
                                {title.ids && title.ids.imdb && <img src="/images/Partner_IMDB.png" alt="" className="mx-2" />}
                                {title.ids && title.ids.jw && <img src="/images/Partner_JW.png" alt="" className="mx-2" />}
                                {title.ids && title.ids.rt && <img src="/images/Partner_RT.png" alt="" className="mx-2" />}
                                {title.ids && title.ids.tmdb && <img src="/images/Partner_TMDB.png" alt="" className="mx-2 py-2" />}
                                {title.ids && title.ids.wiki && <img src="/images/Partner_Wikipedia.png" alt="" className="mx-2" />}

                                {(title.ids === undefined || title.ids.length === 0) && "None set"}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                Poster
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <div className="flex items-center gap-x-3">
                                    {title.thumbnail !== undefined && <img src={title.thumbnail} alt="" />}
                                    {title.thumbnail === undefined && "Not set"}
                                </div>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                            <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                Trailer
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                <div className="flex items-center gap-x-3">
                                    {title.trailer !== undefined &&
                                        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${title.trailer}`} title="YouTube video player" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen></iframe>
                                    }

                                    {title.trailer === undefined && "Not set"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}