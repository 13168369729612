import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';

export default function useTitle(titleId) {
    const firestore = useFirestore();
    const documentRef = doc(firestore, 'titles', titleId);

    // eslint-disable-next-line no-unused-vars
    const { status, data: title } = useFirestoreDocData(documentRef, { idField: "id" });

    if (status !== 'success') {
        return undefined;
    }

    return title;
}