import { useState } from 'react' 
import PageHeader from './Support/PageHeader'
import TitleListPage from './TitleListPage'
import TitleSearchResults from './TitleSearchResults';

export default function DashboardPage({ authUser, setOpen }) {
    const [searchTerm, setSearchTerm] = useState(undefined);

    const queryHandler = (term) => {
        setSearchTerm(term);
    }

    return (
        <>
            <PageHeader title={undefined} setSidebarOpen={setOpen} authUser={authUser} handler={queryHandler} />

            {!searchTerm && <TitleListPage authUser={authUser} setOpen={setOpen} />}
            {searchTerm && <TitleSearchResults authUser={authUser} searchTerm={searchTerm}/>}
        </>
    )
}