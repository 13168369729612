import React from 'react';
import { initializeFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { AuthProvider, FirestoreProvider, useFirebaseApp, useInitFirestore } from 'reactfire';
import AuthCheck from './AuthCheck.js';

export default function PortalPage(props) {
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
    const auth = getAuth(app);
    const { /*status,*/ data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
        const db = initializeFirestore(firebaseApp, {});

        // For offline persistence 
        // per https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
        // await enableIndexedDbPersistence(db);

        return db;
    });

    // Need a sub component because useSigninCheck can't be used at this level,
    // and can't put the AuthProvider in the root level index either, 
    // hence ApplicationHome component

    // Ditto for a context to hold the user info record 

    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestoreInstance}>
                <AuthCheck />
            </FirestoreProvider>
        </AuthProvider>
    );
}