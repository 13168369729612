import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, PlusIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';
import useUserInfo from '../Hooks/useUserInfo';
import debounce from 'lodash.debounce';
import React, { useEffect, useRef } from 'react';

const userNavigation = [
    { name: 'Profile', href: '/app/profile' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PageHeader({ authUser, title, setSidebarOpen, handler }) {
    // HOOKS
    const app = useFirebaseApp();
    const auth = getAuth(app);
    const navigate = useNavigate();
    const userinfo = useUserInfo(authUser.uid);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
                event.preventDefault(); // Prevent the default browser "Find" functionality
                inputRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const signOut = () => {
        auth.signOut().then(() => {
            navigate('/');
            window.location.reload();

        });
    }

    const gotoAdd = () => {
        navigate(`/app/title/add`);
    }

    const searchChanged = debounce((event) => {
        handler(event.target.value);
    }, 500);

    return (
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            {/* If no title set, assume main, allow search */}
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

                {/* if title not set, then show search form */}
                {!title && <form className="relative flex flex-1" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                        Search
                    </label>
                    <MagnifyingGlassIcon className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" aria-hidden="true" />
                    <input id="search-field" ref={inputRef} onChange={searchChanged} className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search" />
                </form>}

                {/* if title is set, then show as passed into props */}
                {title && <div className="relative flex flex-1">
                    <div className="flex h-full w-full items-center ">
                        <span className='text-gray-900 text-2xl font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight'>{title}</span>
                    </div>
                </div>}


                <div className="flex items-center gap-x-4 lg:gap-x-6">
                    {!title && <button type="button" onClick={gotoAdd} className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Add title</span>
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />
                    </button>}

                    {/* Separator */}
                    <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open user menu</span>
                            {/* avatar img ; add ml-4 to name span */}
                            <span className="hidden lg:flex lg:items-center">
                                <span className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                    {(userinfo && userinfo.fname) && userinfo.fname}
                                    {(!userinfo || !userinfo.fname) && "User"}
                                </span>
                                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Menu.Button>
                        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <Link to={item.href} className={classNames(active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}>
                                                {item.name}
                                            </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                                <Menu.Item key='signout'>
                                    {({ active }) => (
                                        <div onClick={() => signOut()} className={classNames(active ? 'bg-gray-50' : '', 'cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900')}>
                                            Sign out
                                        </div>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    );
}