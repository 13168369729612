import React, { Component } from "react";
import Downshift from "downshift";
import debounce from 'lodash.debounce';
import AddActorInline from './AddActorInline.js';
import { getFirestore, query, where, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// https://codesandbox.io/examples/package/downshift
// https://codesandbox.io/s/1rj327v2jj

// state is passed up via props.handler
export default class ActorAutocomplete extends Component {
    constructor(props) {
        super(props);

        const sc = this.props.showCreateOption === true ? true : false;
        this.state = { cast: [], loading: false, showCreateOption: sc, showCreateInterface: false, currentSearchTerm: undefined };
        this.fetchCast = this.fetchCast.bind(this);
        this.handleAddActorChange = this.handleAddActorChange.bind(this);
        this.inputOnChange = this.inputOnChange.bind(this);
        this.handlerFunc = this.handlerFunc.bind(this);

        this.emitChangeDebounced = debounce(this.inputOnChange, 300);

        const config = {
            apiKey: "AIzaSyD6RfNtDIEKhjXpzeWnBW_xUZjgFkILSeI",
            authDomain: "findflix-1e61f.firebaseapp.com",
            projectId: "findflix-1e61f",
            storageBucket: "findflix-1e61f.appspot.com",
            messagingSenderId: "21082022876",
            appId: "1:21082022876:web:4f6232412df3097d6da519",
            measurementId: "G-Z86DHZV8TV"
        };
        const firebaseApp = initializeApp(config);
        this.db = getFirestore(firebaseApp);
    }

    handlerFunc(p1) {
        this.props.handler(p1, this.props.sfv, this.props.sft)
    }

    inputOnChange(event) {
        if (!event.target.value) {
            this.setState({ cast: [] });
            return;
        }

        this.setState({ currentSearchTerm: event.target.value });
        this.fetchCast(event.target.value);
    }

    downshiftOnChange(selectedActor) {
        console.log(`[ActorAutocomplete] selected actor ${selectedActor.id} / ${selectedActor.name}`);

        if (selectedActor.id === '0') {
            console.log("[ActorAutocomplete] show create actor interface");
            this.setState({ showCreateInterface: true });
        } else {
            // lift state upwards
            console.log(`[ActorAutocomplete] lift selected actor upwards ${selectedActor.id}`);
            this.handlerFunc(selectedActor);
        }
    }

    // handles the fetch from firestore
    async nameSearch(searchTerm) {
        const castCollection = collection(this.db, "cast");
        const queryFilter = query(castCollection, where("name", ">=", searchTerm), where("name", "<=", searchTerm + '\uf8ff'));
        const querySnapshot = await getDocs(queryFilter);
        const castArray = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        return castArray;
    }

    async fetchCast(searchTerm) {
        if (searchTerm === undefined || searchTerm === null || searchTerm.trim() === '') {
            return
        }

        this.setState({ loading: true });
        var re = [];
        const searchResults = await this.nameSearch(searchTerm);
        if (searchResults.length > 0) {
            re.push(...searchResults);
        } else {
            re = [];
        }
        if (this.state.showCreateOption === true) {
            re.push({ id: '0', name: 'Create new record' })
        }
        this.setState({ cast: re });
        this.setState({ loading: false });
    }

    // LIFTING STATE FROM actor addinline

    handleAddActorChange(selectedActor) {
        console.log("[ActorAutocomplete] handleAddActorChange() " + JSON.stringify(selectedActor));
        this.setState({ showCreateInterface: false });
        this.handlerFunc(selectedActor);
    }

    render() {
        return (
            <>
                <AddActorInline open={this.state.showCreateInterface} onChange={this.handleAddActorChange} autoc={this.state.currentSearchTerm} />
                <Downshift onChange={this.downshiftOnChange.bind(this)} itemToString={item => (item ? item.name : "")} >
                    {({
                        selectedItem,
                        getInputProps,
                        getItemProps,
                        highlightedIndex,
                        isOpen,
                        // inputValue
                    }) => (
                        <div className="relative">
                            <div className='absolute top-3 right-2'>
                                {this.state.loading && <img src='/images/loadingtiny.gif' width='16' height='16' alt='' />}
                            </div>
                            <input className="w-full p-2 text-sm focus:outline-none focus:shadow-outline rounded border border-gray-300 focus:ring-1 focus:ring-isober-500 focus:border-isober-500"
                                {...getInputProps({
                                    placeholder: "Start typing...",
                                    onChange: this.emitChangeDebounced
                                })}
                            />
                            {isOpen ? (
                                <div className="border border-gray-300 bg-white shadow-md">
                                    {this.state.cast
                                        .slice(0, 10)
                                        .map((item, index) => (
                                            <div
                                                className="dropdown-item"
                                                {...getItemProps({ key: index, index, item })}
                                                style={{
                                                    backgroundColor: highlightedIndex === index ? "lightgray" : "white",
                                                    fontWeight: selectedItem === item ? "bold" : "normal"
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                        ))}
                                </div>
                            ) : null}
                        </div>

                    )}
                </Downshift>
            </>
        );
    }
}