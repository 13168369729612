import { collection, query, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function usePaginatedFeedback(startItem, include, rpp) {
    const firestore = useFirestore();
    const feedbackCollection = collection(firestore, 'feedback');

    var activeQuery = undefined;
    if (startItem === null) {
        activeQuery = query(feedbackCollection, orderBy('created', 'desc'), limit(rpp))
    } else {
        activeQuery = (include) ? 
            query(feedbackCollection,  orderBy('created', 'desc'), limit(rpp), startAt(startItem)) : 
            query(feedbackCollection, orderBy('created', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: feedbacks } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return feedbacks;
}
