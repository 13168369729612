import './App.css';
import { useState } from 'react'
import Footer from './Footer';
import ContactDialog from './ContactDialog';
import SignInDialog from './SignInDialog';
import HeroFragment1 from './HeroFragment1';
import HeroFragment2 from './HeroFragment2';
import HeadlineFragment from './HeadlineFragment';
import LogoCloud from './LogoCloud';

export default function LandingPage() {
    const [contactDialogOpen, setContactDialogOpen] = useState(false)
    const [signinDialogOpen, setSigninDialogOpen] = useState(false)

    const showContact = () => {
        setContactDialogOpen(true);
    }

    const hideContact = () => {
        setContactDialogOpen(false);
    }

    const showSignIn = () => {
        setSigninDialogOpen(true);
    }

    const hideSignIn = () => {
        setSigninDialogOpen(false);
    }

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-10">
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="lg:flex lg:gap-x-12">
                        <button onClick={() => showContact()} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            Contact
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <button onClick={() => showSignIn()} className="text-sm font-semibold leading-6 text-gray-900">
                            Log in <span aria-hidden="true">&rarr;</span>
                        </button>
                    </div>
                </nav>
            </header>

            {signinDialogOpen && <SignInDialog closeHandler={hideSignIn} />}
            {contactDialogOpen && <ContactDialog closeHandler={hideContact} />}

            <HeroFragment1 />
            <HeroFragment2 />
            <HeadlineFragment />
            <LogoCloud />
            <Footer />
        </div>
    )
}