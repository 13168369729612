import { useState, useRef } from 'react'
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from "yup";
import { useParams, useNavigate } from 'react-router-dom';
import GenreChooser from "./Components/GenreChooser";
import TagChooser from "./Components/TagChooser";
import DirectorAutocomplete from './Components/DirectorAutocomplete';
import ModalLoading from './Components/ModalLoading';
import { ErrorBanner } from './Components/Banners';
import { useFirestore, useAuth } from 'reactfire';
import { getApp } from "firebase/app";
import { doc, updateDoc, serverTimestamp, deleteField } from 'firebase/firestore';
import { getStorage } from "firebase/storage"; // ref, uploadBytes
import Resizer from "react-image-file-resizer";
import useTitle from "./Hooks/useTitle";
import { formatDateDMY } from './Functions/dates.js';
import cloneDeep from 'lodash/cloneDeep';
import { PlusIcon } from '@heroicons/react/20/solid'
import AddProducerModal from './Components/AddProducerModal';
import AddActorModal from './Components/AddActorModal';
import AddWatchModal from './Components/AddWatchModal';
import CountryChooser from './Components/CountryChooser';
import LanguageChooser from './Components/LanguageChooser';

// https://firebase.google.com/docs/storage/web/start re multiple buckets
// https://stackoverflow.com/questions/56149756/reactjs-how-to-handle-image-file-upload-with-formik

export default function TitleEditPage({ authUser }) {
    // STATE

    // eslint-disable-next-line
    const [debugShowFormErrors, setDebugShowFormErrors] = useState(true);

    // eslint-disable-next-line
    const [artworkImgEncoded, setArtworkImgEncoded] = useState(undefined);

    // eslint-disable-next-line
    const [artworkImgFile, setArtworkImgFile] = useState(undefined);
    const [modalProducerOpen, setModalProducerOpen] = useState(false);
    const [modalActorOpen, setModalActorOpen] = useState(false);
    const [modalWatchOpen, setModalWatchOpen] = useState(false);
    const [modalSpinnerOpen, setModalSpinnerOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [modalMessage, setModalMessage] = useState(undefined);

    // HOOKS
    const { titleId } = useParams();
    const title = useTitle(titleId); // ready to use JS Object
    const firebaseApp = getApp();
    // eslint-disable-next-line
    const storage = getStorage(firebaseApp, "gs://findflix-cdn");
    const auth = useAuth();
    const firestore = useFirestore();
    const navigate = useNavigate();
    const artworkImgRef = useRef()
    const ratingFieldRef = useRef();
    const formikRef = useRef();

    // munge firestore structure into state array of simple objects
    // const flattenedArray = [];
    // for (const guid in (title.producers) ? title.producers : {}) {
    //     for (const titleX in title.producers[guid]) {
    //         console.log(JSON.stringify(title.producers[guid]));
    //         flattenedArray.push({ guid: guid, title: titleX, name: title.producers[guid][titleX] });
    //     }
    // }

    const [selectedDirectors, setSelectedDirectors] = useState((title.directors) ? Object.entries(title.directors).map(([guid, name]) => ({ guid, name })) : []);
    const [selectedShowrunners, setSelectedShowrunners] = useState((title.showrunners) ? title.showrunners : []);
    const [selectedCreators, setSelectedCreators] = useState((title.creators) ? title.creators : []);
    const [selectedProducers, setSelectedProducers] = useState((title.producers) ? title.producers : []);
    const [selectedCast, setSelectedCast] = useState((title.cast) ? title.cast : []);
    const [selectedWatch, setSelectedWatch] = useState((title.watch) ? title.watch : []);
    const [useSeriesMetadata, setUseSeriesMetadata] = useState((title.kind && (title.kind === 2 || title.kind === "2")) ? true : false);

    // FORM
    const initialValues = {
        titleDisplay: title.title,
        genres: (title.genres) ? title.genres : [],
        tags: (title.tags) ? title.tags : [],
        synopsis: title.synopsis,
        kind: title.kind,
        released: (title.release_date) ? formatDateDMY(title.release_date.toDate()) : undefined,
        runtimeHH: title.runtimeHH,
        runtimeMM: title.runtimeMM,
        runtime: title.runtime,
        rating: title.rating,
        trailer: title.trailer,
        featured: (title.featured) ? title.featured : false,
        directorGuid: (title.directors) ? Object.keys(title.directors)[0] : undefined,
        directorName: (title.directors) ? Object.values(title.directors)[0] : undefined,
        idJW: (title.ids && title.ids.jw) ? title.ids.jw : undefined,
        idRT: (title.ids && title.ids.rt) ? title.ids.rt : undefined,
        idTMDB: (title.ids && title.ids.tmdb) ? title.ids.tmdb : undefined,
        idWiki: (title.ids && title.ids.wiki) ? title.ids.wiki : undefined,
        country: (title.country && title.country.length > 0) ? title.country[0] : undefined,
        language: title.language,
        numEpisodes: (title.numEpisodes !== null && title.numEpisodes !== undefined) ? title.numEpisodes : undefined,
    };
    const validationSchema = Yup.object({
        titleDisplay: Yup.string("Enter title").required("Required"),
        synopsis: Yup.string("Enter synopsis").required("Required"),
        trailer: Yup.string("Enter trailer").required("Required"),
        directorGuid: Yup.string("Director ID").optional(),
        directorName: Yup.string("Director name").optional(),
        genres: Yup.array().min(1, "Choose at least 1").required("Required"),
        tags: Yup.array().optional(),
        kind: Yup.string().oneOf(["1", "2"], "Must agree to something").required(),
        released: Yup.string("Enter release date").optional().matches(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/, "Invalid date"),
        runtime: Yup.string("Enter runtime").optional().matches(/^[0-9hmHM ]{1,20}$/, "Invalid format"),
        runtimeHH: Yup.number("Enter runtime HH").optional().min(0).max(23).typeError('HH must be a number'),
        runtimeMM: Yup.number("Enter runtime MM").optional().min(0).max(59).typeError('MM must be a number'),
        rating: Yup.string("Enter rating").optional().matches(/^[0-9A-Za-z\-+ ]{1,20}$/, "Invalid characters"),
        idIMDB: Yup.string("Enter IMDB").optional().matches(/^tt\d{7}$/, "Invalid ID"),
        idJW: Yup.string("Enter Just Watch").optional().matches(/^\//, "Invalid ID"),
        idRT: Yup.string("Enter Rotten Tomatoes").optional().matches(/^\//, "Invalid ID"),
        idTMDB: Yup.string("Enter TMDB").optional().matches(/^\//, "Invalid ID"),
        idWiki: Yup.string("Enter Wikipedia").optional(),
        featured: Yup.boolean().required(),
        country: Yup.string("Enter country").optional().matches(/^[a-zA-Z]{2}$/, "Invalid country"),
        language: Yup.string("Enter language").optional().matches(/^[a-zA-Z]{2}$/, "Invalid language"),
        numEpisodes: Yup.number("Enter number of episodes").optional().min(0).max(1000).typeError('Number of episodes must be a number'),
    });

    // FORM ACTION

    async function handleSubmit(form) {
        // get ready in the following state
        setErrorMessage(undefined);
        setModalSpinnerOpen(true);

        var payload = {
            updator: auth.currentUser.uid,
            updated: serverTimestamp(),
            title: form.titleDisplay,
            title_sort: form.titleDisplay,
            synopsis: form.synopsis,
            trailer: form.trailer,
            genres: form.genres,
            tags: form.tags,
            kind: parseInt(form.kind)
        };

        // add if valid
        if (form.released !== undefined && parseDateString(form.released) instanceof Date) {
            payload.release_date = parseDateString(form.released);
        }

        // add if valid
        if (form.runtimeHH !== undefined && form.runtimeMM !== undefined && !isNaN(form.runtimeHH) && !isNaN(form.runtimeMM)) {
            payload.runtimeHH = parseInt(form.runtimeHH);
            payload.runtimeMM = parseInt(form.runtimeMM);
        }

        // add if valid
        if (form.rating !== undefined) {
            payload.rating = form.rating;
        }

        // add site IDs if valid
        const siteIds = {};
        if (form.idIMDB !== undefined) {
            siteIds['imdb'] = form.idIMDB;
        }
        if (form.idJW !== undefined) {
            siteIds['jw'] = form.idJW;
        }
        if (form.idRT !== undefined) {
            siteIds['rt'] = form.idRT;
        }
        if (form.idTMDB !== undefined) {
            siteIds['tmdb'] = form.idTMDB;
        }
        if (form.idWiki !== undefined) {
            siteIds['wiki'] = form.idWiki;
        }

        if (Object.keys(siteIds).length > 0) {
            payload.ids = siteIds;
        }

        // add Directors if valid
        if (selectedDirectors !== undefined && Array.isArray(selectedDirectors)) {
            var directors = {};

            selectedDirectors.forEach(item => {
                directors[item.guid] = item.name;
            });

            payload.directors = directors;
        }

        // add Showrunners if valid
        if (selectedShowrunners !== undefined && Array.isArray(selectedShowrunners)) {
            var showrunners = [];
            var showrunnersIdx = [];

            selectedShowrunners.forEach(item => {
                showrunners.push(item);
                showrunnersIdx.push(item.guid);
            });

            payload.showrunners = showrunners;
            payload.showrunnersIdx = showrunnersIdx;
        }

        // add Creators if valid
        if (selectedCreators !== undefined && Array.isArray(selectedCreators)) {
            var creators = [];
            var creatorsIdx = [];

            selectedCreators.forEach(item => {
                creators.push(item);
                creatorsIdx.push(item.guid);
            });

            payload.creators = creators;
            payload.creatorsIdx = creatorsIdx;
        }

        // add Producers if valid
        if (selectedProducers !== undefined && Array.isArray(selectedProducers)) {
            var producers = [];
            var producersIdx = [];

            selectedProducers.forEach(item => {
                producers.push(item);
                producersIdx.push(item.guid);
            });

            payload.producers = producers;
            payload.producersIdx = producersIdx;
        }

        // add Cast if valid
        if (selectedCast !== undefined && Array.isArray(selectedCast)) {
            var cast = [];
            var castIdx = [];

            selectedCast.forEach(item => {
                cast.push(item);
                castIdx.push(item.guid);
            });

            payload.cast = cast;
            payload.castIdx = castIdx;
        }

        // add Watch if valid
        if (selectedWatch !== undefined && Array.isArray(selectedWatch)) {
            var watchItems = [];

            selectedWatch.forEach(item => {
                watchItems.push(item);
            });

            payload.watch = watchItems;
        }

        // add Country if valid
        if (form.country !== undefined && form.country.length === 2) {
            payload.country = [form.country];
        }

        // add Language if valid
        if (form.language && form.language.length === 2) {
            payload.language = form.language;
        }

        // add num episodes if valid
        if (form.numEpisodes !== undefined && form.numEpisodes.trim().length > 0) {
            let intVal = parseInt(form.numEpisodes);
            if (!isNaN(intVal)) {
                payload.numEpisodes = intVal;
            }
        } else if (form.kind === 2 || form.kind === "2") {
            // delete the field on the document if the user blanked the form field
            payload.numEpisodes = deleteField();
        }

        // Debug:
        // window.alert(JSON.stringify(payload)); return;

        setModalMessage('Updating title...');
        console.log("Updating doc " + titleId);

        // PART A: save the record
        const titleDocRef = doc(firestore, "titles", titleId);
        await updateDoc(titleDocRef, payload)
            .catch(error => {
                console.log("Error updating doc: " + error);
            });

        // FIXME: allow a new photo upload

        // pressing save goes back to details rather than main
        navigate(`/app/title/${title.id}`);
    }

    // HELPERS

    const parseDateString = (str) => {
        const [day, month, year] = str.split('/').map(Number);
        const parsedDate = new Date(year, month - 1, day);
        return parsedDate;
    }

    const clearDirectorPressed = async (director, setFieldValue, setFieldTouched) => {
        const directorsClone = cloneDeep(selectedDirectors);
        var removeIndex = directorsClone.map(item => item.guid).indexOf(director.guid);
        (removeIndex >= 0) && directorsClone.splice(removeIndex, 1);

        await setSelectedDirectors(directorsClone);
    }

    const clearShowrunnerPressed = async (showrunner, setFieldValue, setFieldTouched) => {
        const showrunnersClone = cloneDeep(selectedShowrunners);
        var removeIndex = showrunnersClone.map(item => item.guid).indexOf(showrunner.guid);
        (removeIndex >= 0) && showrunnersClone.splice(removeIndex, 1);

        await setSelectedShowrunners(showrunnersClone);
    }

    const clearCreatorPressed = async (creator, setFieldValue, setFieldTouched) => {
        const creatorsClone = cloneDeep(selectedCreators);
        var removeIndex = creatorsClone.map(item => item.guid).indexOf(creator.guid);
        (removeIndex >= 0) && creatorsClone.splice(removeIndex, 1);

        await setSelectedCreators(creatorsClone);
    }

    const cancelPressed = () => {
        if (title && title.id) {
            navigate(`/app/title/${title.id}`);
        } else {
            navigate(`/app`);
        }
    }

    // from DirectorAutocomplete
    const handleDirectorChange = async (newVal, setFieldValue, setFieldTouched) => {
        if (newVal === undefined || !newVal.id || !newVal.name) {
            return;
        }

        if (selectedDirectors.some(element => {
            if (element.guid === newVal.id) {
                return true;
            }

            return false;
        })) {
            return;
        }

        // keep our own copy outside the form to display to the user
        await setSelectedDirectors([{ guid: newVal.id, name: newVal.name }, ...selectedDirectors]);
    }

    // from DirectorAutocomplete
    const handleShowrunnerChange = async (newVal, setFieldValue, setFieldTouched) => {
        if (newVal === undefined || !newVal.id || !newVal.name) {
            return;
        }

        // check if already in list
        if (selectedShowrunners.some(element => {
            if (element.guid === newVal.id) {
                return true;
            }

            return false;
        })) {
            return;
        }

        // keep our own copy outside the form to display to the user
        await setSelectedShowrunners([{ guid: newVal.id, name: newVal.name }, ...selectedShowrunners]);
    }

    // from DirectorAutocomplete
    const handleCreatorChange = async (newVal, setFieldValue, setFieldTouched) => {
        if (newVal === undefined || !newVal.id || !newVal.name) {
            return;
        }

        // check if already in list
        if (selectedCreators.some(element => {
            if (element.guid === newVal.id) {
                return true;
            }

            return false;
        })) {
            return;
        }

        // keep our own copy outside the form to display to the user
        await setSelectedCreators([{ guid: newVal.id, name: newVal.name }, ...selectedCreators]);
    }

    const clearProducerPressed = async (producer, setFieldValue, setFieldTouched) => {
        const producersClone = cloneDeep(selectedProducers);

        var removeIndex = producersClone.map(item => item.guid).indexOf(producer.guid);
        (removeIndex >= 0) && producersClone.splice(removeIndex, 1);

        await setSelectedProducers(producersClone);

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('producersShadow', new Date());
        formikRef.current.setFieldTouched('producersShadow', true);
    }

    const clearCastPressed = async (actor, setFieldValue, setFieldTouched) => {
        const castClone = cloneDeep(selectedCast);

        var removeIndex = castClone.map(item => item.guid).indexOf(actor.guid);
        (removeIndex >= 0) && castClone.splice(removeIndex, 1);

        await setSelectedCast(castClone);

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('castShadow', new Date());
        formikRef.current.setFieldTouched('castShadow', true);
    }

    const clearWatchPressed = async (watchItem, setFieldValue, setFieldTouched) => {
        const watchClone = cloneDeep(selectedWatch);

        var removeIndex = watchClone.map(item => item.url).indexOf(watchItem.url);
        (removeIndex >= 0) && watchClone.splice(removeIndex, 1);

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('watchShadow', new Date());
        formikRef.current.setFieldTouched('watchShadow', true);

        await setSelectedWatch(watchClone);
    }

    const showAddProducer = (e) => {
        e.stopPropagation();

        setModalProducerOpen(true);
    }

    const showAddActor = (e) => {
        e.stopPropagation();

        setModalActorOpen(true);
    }

    const showAddWatch = (e) => {
        e.stopPropagation();

        setModalWatchOpen(true);
    }

    const addProducerHandler = async (item) => {
        setModalProducerOpen(false);

        // validate
        if (!item || !item.guid || !item.name || !item.title) {
            return;
        }

        // will receive:  {"guid":"wpjc8t3wRlPdP3NRigtS","name":"Christopher Nolan","title":"Producer"}
        await setSelectedProducers([item, ...selectedProducers]);

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('producersShadow', new Date());
        formikRef.current.setFieldTouched('producersShadow', true);
    }

    const addActorHandler = async (item) => {
        setModalActorOpen(false);

        // validate
        if (!item || !item.guid || !item.name) {
            return;
        }

        // will receive:  {"guid":"wpjc8t3wRlPdP3NRigtS","name":"Christopher Nolan","role":"Producer"}
        await setSelectedCast([item, ...selectedCast]);

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('castShadow', new Date());
        formikRef.current.setFieldTouched('castShadow', true);
    }

    const addWatchHandler = async (item) => {
        setModalWatchOpen(false);

        // validate
        if (!item || !item.title || !item.url) {
            return;
        }

        // Force Formik to see the form as Dirty else save button stays greyed out
        formikRef.current.setFieldValue('watchShadow', new Date());
        formikRef.current.setFieldTouched('watchShadow', true);

        // will receive:  {"guid":"wpjc8t3wRlPdP3NRigtS","name":"Christopher Nolan","role":"Producer"}
        await setSelectedWatch([item, ...selectedWatch]);
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(file, 200, 300, "PNG", 95, 0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    // eslint-disable-next-line
    const handleArtworkImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            artworkImgRef.current.file = file;
            reader.onload = async (e) => {
                artworkImgRef.current.src = e.target.result;
                const deprefixed = e.target.result.replace(/^(data:image\/png;base64,)/, ''); // data:image/png;base64,
                setArtworkImgEncoded(deprefixed);
                const resizedImage = await resizeFile(file);
                setArtworkImgFile(resizedImage);
            }
            reader.readAsDataURL(file);
        }
    };

    const notRatedChanged = (e, setFieldValue, setFieldTouched) => {
        if (e !== undefined && e.target.checked) {
            setFieldValue('rating', "Not rated");
            setFieldTouched('rating', true);
            ratingFieldRef.current.value = "Not rated";
            ratingFieldRef.current.disabled = true;
        } else {
            setFieldValue('rating', undefined);
            setFieldTouched('rating', true);
            ratingFieldRef.current.value = "";
            ratingFieldRef.current.disabled = false;
        }
    }

    const countryHandler = async (item, setFieldValue, setFieldTouched) => {
        // window.alert(JSON.stringify(item));

        if (item && item.id && item.id.length === 2) {
            await setFieldValue('country', item.id);
            await setFieldTouched('country', true);
        }
    }

    const languageHandler = async (item, setFieldValue, setFieldTouched) => {
        // window.alert(JSON.stringify(item));

        if (item && item.id && item.id.length === 2) {
            await setFieldValue('language', item.id);
            await setFieldTouched('language', true);
        }
    }

    return (
        <>
            {modalSpinnerOpen && <ModalLoading open={modalSpinnerOpen} message={modalMessage} />}
            {modalProducerOpen && <AddProducerModal isOpen={modalProducerOpen} handler={addProducerHandler} />}
            {modalActorOpen && <AddActorModal isOpen={modalActorOpen} handler={addActorHandler} />}
            {modalWatchOpen && <AddWatchModal isOpen={modalWatchOpen} handler={addWatchHandler} />}

            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount={true} innerRef={formikRef}>
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                    <form onSubmit={handleSubmit} className="">

                        <div className="space-y-12 sm:space-y-16">
                            <div>
                                <div className="sticky top-0 z-30 py-2 bg-white border-b border-gray-300">
                                    <div className='sm:flex sm:items-center sm:justify-between'>
                                        <h1 className="my-2 text-2xl font-bold text-gray-900 sm:text-3xl">
                                            Edit Title: &nbsp;
                                            {title.title}
                                        </h1>
                                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                                            <button type="button" onClick={cancelPressed} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="my-2 max-w-2xl text-sm leading-6 text-gray-600">
                                            {debugShowFormErrors && errors && Object.keys(errors).length > 0 && Object.keys(errors).map((key) => (
                                                <span key={key} className="text-orange-500">{key}: {errors[key]} ; &nbsp;</span>
                                            ))}
                                        </p>
                                    </div>
                                </div>

                                {errorMessage && <ErrorBanner message={errorMessage} />}

                                <div className="mt-2 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="title-display" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Display title &nbsp;
                                            <ErrorMessage name="titleDisplay" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="">
                                                <input type="text" name="titleDisplay" id="titleDisplay" autoComplete="off" defaultValue={initialValues.titleDisplay} onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="synopsis" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Synopsis &nbsp;
                                            <ErrorMessage name="synopsis" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <textarea id="synopsis" name="synopsis" rows={4} autoComplete="off" defaultValue={initialValues.synopsis} onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="synopsis" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Director(s) &nbsp;
                                            <ErrorMessage name="director" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            {(selectedDirectors !== undefined && Array.isArray(selectedDirectors) && selectedDirectors.length > 0) && <div>
                                                {selectedDirectors.map((director, index) => (
                                                    <div key={index}>
                                                        <span className='text-green-700 pl-1'>{director.name}</span>
                                                        <button type="button" onClick={() => clearDirectorPressed(director, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>}
                                            <DirectorAutocomplete handler={handleDirectorChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={authUser} />
                                        </div>

                                        {(useSeriesMetadata === true) && <>
                                            <label htmlFor="showrunner" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                Showrunner(s) &nbsp;
                                                <ErrorMessage name="showrunner" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                {(selectedShowrunners !== undefined && Array.isArray(selectedShowrunners) && selectedShowrunners.length > 0) && <div>
                                                    {selectedShowrunners.map((showrunner, index) => (
                                                        <div key={index}>
                                                            <span className='text-green-700 pl-1'>{showrunner.name}</span>
                                                            <button type="button" onClick={() => clearShowrunnerPressed(showrunner, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                                Remove
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>}
                                                <DirectorAutocomplete handler={handleShowrunnerChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={authUser} />
                                            </div>

                                            <label htmlFor="creators" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                Creator(s) &nbsp;
                                                <ErrorMessage name="creators" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                {(selectedCreators !== undefined && Array.isArray(selectedCreators) && selectedCreators.length > 0) && <div>
                                                    {selectedCreators.map((creator, index) => (
                                                        <div key={index}>
                                                            <span className='text-green-700 pl-1'>{creator.name}</span>
                                                            <button type="button" onClick={() => clearCreatorPressed(creator, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                                Remove
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>}
                                                <DirectorAutocomplete handler={handleCreatorChange} sfv={setFieldValue} sft={setFieldTouched} showCreateOption={true} userinfo={authUser} />
                                            </div>

                                            <label htmlFor="rating" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                Number of episodes <br />
                                                <span className='text-gray-400'>Optional</span> <br />
                                                <ErrorMessage name="numEpisodes" component="span" className="text-red-700 italic" />
                                            </label>
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                    <div className="flex items-center gap-x-3">
                                                        <div className="my-1 pl-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                            <input type="text" name="numEpisodes" id="numEpisodes" defaultValue={initialValues.numEpisodes} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}

                                        <label htmlFor="synopsis" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Producer(s) &nbsp;
                                            <ErrorMessage name="director" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            {(selectedProducers !== undefined && Array.isArray(selectedProducers) && selectedProducers.length > 0) && <div>
                                                {selectedProducers.map((producer, index) => (
                                                    <div key={index}>
                                                        <span className='text-green-700 pl-1'>{producer.title}: {producer.name}</span>
                                                        <button type="button" onClick={() => clearProducerPressed(producer, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>}
                                            <button type="button" onClick={showAddProducer} className="inline-flex gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                Add producer
                                            </button>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="synopsis" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Cast &nbsp;
                                            <ErrorMessage name="cast" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            {(selectedCast !== undefined && Array.isArray(selectedCast) && selectedCast.length > 0) && <div>
                                                {selectedCast.map((actor, index) => (
                                                    <div key={index}>
                                                        <span className='text-green-700 pl-1'>{actor.name} {actor.role && <span>: {actor.role}</span>}</span>
                                                        <button type="button" onClick={() => clearCastPressed(actor, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>}
                                            <button type="button" onClick={showAddActor} className="inline-flex gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                Add actor
                                            </button>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="synopsis" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Where to watch  <br />
                                            <span className='text-gray-400'>Optional</span> <br />
                                            <ErrorMessage name="watch" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            {(selectedWatch !== undefined && Array.isArray(selectedWatch) && selectedWatch.length > 0) && <div>
                                                {selectedWatch.map((watchItem, index) => (
                                                    <div key={index}>
                                                        <span className='text-green-700 pl-1'>{watchItem.title} at: <u>{watchItem.url}</u></span>
                                                        <button type="button" onClick={() => clearWatchPressed(watchItem, setFieldValue, setFieldTouched)} className="mx-3 my-2 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100">
                                                            Remove
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>}
                                            <button type="button" onClick={showAddWatch} className="inline-flex gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                Add item
                                            </button>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Kind &nbsp;
                                            <ErrorMessage name="kind" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="flex items-center gap-x-3">
                                                <input id="kind-movie" name="kind" value="1" onClick={() => { setUseSeriesMetadata(false); }} defaultChecked={initialValues.kind === "1" || initialValues.kind === 1} type="radio" onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                <label htmlFor="kind-movie" className="block text-sm leading-6 text-gray-900">
                                                    Movie
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <input id="kind-series" name="kind" value="2" onClick={() => { setUseSeriesMetadata(true); }} defaultChecked={initialValues.kind === "2" || initialValues.kind === 2} type="radio" onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                <label htmlFor="kind-series" className="block text-sm leading-6 text-gray-900">
                                                    Series
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="released" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Release date &nbsp;
                                            <ErrorMessage name="released" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="pl-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input type="text" name="released" id="released" defaultValue={initialValues.released} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="DD/MM/YYYY" />
                                            </div>
                                        </div>

                                        <label htmlFor="runtime" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Runtime &nbsp;
                                            <ErrorMessage name="runtimeHH" component="span" className="text-red-700 italic" /> &nbsp;
                                            <ErrorMessage name="runtimeMM" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                <div className="flex items-center gap-x-3">

                                                    <div className="relative mt-2 rounded-md shadow-sm">
                                                        <input type="text" name="runtimeHH" id="runtimeHH" defaultValue={initialValues.runtimeHH} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                Hours
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="relative mt-2 rounded-md shadow-sm">
                                                        <input type="text" name="runtimeMM" id="runtimeMM" defaultValue={initialValues.runtimeMM} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="0" />
                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                                Minutes
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <label htmlFor="rating" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Rating &nbsp;
                                            <ErrorMessage name="rating" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                <div className="flex items-center gap-x-3">
                                                    <div className="my-1 pl-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                        <input type="text" name="rating" id="rating" ref={ratingFieldRef} defaultValue={initialValues.rating} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="PG13" />
                                                    </div>
                                                    <div>
                                                        <input type="checkbox" id="ratingNotRated" onChange={(e) => { notRatedChanged(e, setFieldValue, setFieldTouched) }} className='mx-2 focus:ring-isober-500 h-4 w-4 text-isober-600 border-gray-300 rounded' />
                                                        <label htmlFor="ratingNotRated" className='text-sm font-medium text-gray-700'>Not rated</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                            Is featured
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0 flex flex-row">
                                            <div className="mr-2 flex items-center gap-x-2">
                                                <input id="featured-yes" name="featured" value="1" type="radio" defaultChecked={initialValues.featured === true} className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                <label htmlFor="featured-yes" className="block text-sm leading-6 text-gray-900">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="ml-2 flex items-center gap-x-2">
                                                <input id="featured-no" name="featured" value="0" type="radio" defaultChecked={initialValues.featured === false} onChange={handleChange} className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                <label htmlFor="featured-no" className="block text-sm leading-6 text-gray-900">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                                        <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                            Country of origin
                                            <br /><span className='text-gray-400'>Optional</span>
                                            <ErrorMessage name="origin" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <CountryChooser handler={countryHandler} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                                        </div>

                                        <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                            Original language
                                            <br /><span className='text-gray-400'>Optional</span>
                                            <ErrorMessage name="language" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <LanguageChooser handler={languageHandler} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                                        <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                            Youtube trailer ID &nbsp;
                                            <ErrorMessage name="trailer" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="pl-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input type="text" name="trailer" id="trailer" defaultValue={initialValues.trailer} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Youtube ID" />
                                            </div>
                                        </div>

                                        <label htmlFor="trailer" className="block text-sm font-medium leading-6 text-gray-900">
                                            Movie Database ID
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <div className="flex items-center gap-x-3">
                                                <div className="my-1 pl-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <input type="text" name="idTMDB" id="idTMDB" defaultValue={initialValues.idTMDB} autoComplete="none" onChange={handleChange} onBlur={handleBlur} className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="/movie/184-jackie-brown" />
                                                </div>
                                                <img src="/images/Partner_TMDB.png" alt="" />
                                                <ErrorMessage name="idTMDB" component="span" className="text-red-700 italic" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Genres
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <Field name="validdevices" component={GenreChooser} initialSelection={initialValues.genres} />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Tags
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            <Field name="validdevices" component={TagChooser} initialSelection={initialValues.tags} />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                            Poster
                                        </label>
                                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                                            {/* Don't allow uploading a new cover until correctly implement deleting the old one:
                                            <input id="file" name="file" type="file" accept="image/png, image/jpeg" onChange={handleArtworkImageUpload} />
                                            <div className='mt-2 font-medium text-sm leading-6 text-gray-900'>
                                                <img ref={artworkImgRef} className='w-40 h-60 border-none border-0' alt="" />
                                            </div>*/}
                                            {title.thumbnail !== undefined && <img src={title.thumbnail} alt="" />}
                                            {title.thumbnail === undefined && "Not set"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Button actions */}
                        <div className="mt-4 flex justify-end">
                            <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-60 bg-indigo-800 hover:bg-indigo-900 py-2 px-4 border border-indigo-900 rounded-md shadow-sm text-sm font-medium text-white disabled:text-gray-2    00 hover:bg-indigo-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Update title
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}