import * as Yup from "yup"
import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// https://wanago.io/2019/10/28/writing-react-forms-with-formik-validating-data-using-yup/

function SignInPage(props) {
    const auth = getAuth();
    const [errorMessage, setErrorMessage] = useState(null);
    const [/*authUser,*/ setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const loginFormInitialValues = { email: '', password: '' };
    const loginFormValidationSchema = Yup.object({
        email: Yup.string("Enter a valid email address").email("Invalid").required("Required"),
        password: Yup.string("Enter a password").required("Required")
    });

    const formatError = (code) => {
        switch (code) {
            case "auth/user-not-found":
                return "The specified account does not exist";
            case "auth/wrong-password":
                return "Incorrect password";
            case "auth/user-disabled":
                return "The specified account is disabled";
            default:
                return `An error occurred (${code})`;
        }
    }

    const handleSubmit = (form) => {
        setIsLoading(true);
        signInWithEmailAndPassword(auth, form.email, form.password)
            .then((userCredential) => {
                const user = userCredential.user;
                setErrorMessage(null);
                setAuthUser({
                    ...user,
                    emailAddress: form.email,
                    loggedIn: true
                });
            })
            .catch((error) => {
                setErrorMessage(formatError(error.code));
                setIsLoading(false);
            });
    }

    return (
        <div>
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <a href="/"><img className="mx-auto h-24 w-auto" src="/images/logo.png" alt="FindFlix" /></a>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Formik initialValues={loginFormInitialValues} onSubmit={handleSubmit} validationSchema={loginFormValidationSchema} >
                            {({
                                handleSubmit, handleChange, handleBlur, values,
                            }) => (
                                <form className="space-y-6" onSubmit={handleSubmit}>
                                    {isLoading &&
                                        <div className="block text-sm"><p className="font-medium text-gray-700">Loading...</p></div>
                                    }

                                    {errorMessage &&
                                        <div className="block text-sm"><p className="font-bold text-red-700">{errorMessage}</p></div>
                                    }

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email address &nbsp;
                                            <ErrorMessage name="email" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <input id="email" name="email" value={values.email} type="email" autoComplete="email" onChange={handleChange} onBlur={handleBlur} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password &nbsp;
                                            <ErrorMessage name="password" component="span" className="text-red-700 italic" />
                                        </label>
                                        <div className="mt-1">
                                            <input id="password" name="password" type="password" value={values.password} autoComplete="current-password" onChange={handleChange} onBlur={handleBlur} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-900 hover:bg-indigo-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >Sign in</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInPage;
