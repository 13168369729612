import React, { useState, useEffect } from 'react';
import { usePaginatedTitles } from "./Hooks/usePaginatedTitles"
import { formatDateShort } from './Functions/dates.js';
import { useNavigate } from 'react-router-dom';

// Get custom claims as follows:
//   import { useIdTokenResult } from 'reactfire';
//   const { data: userIdToken } = useIdTokenResult(authUser);

export default function TitleListPage({ authUser }) {
    // STATE
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedTitles()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const resultsPerPage = 10;

    // HOOKS
    const titles = usePaginatedTitles(referenceItem, include, resultsPerPage);
    const navigate = useNavigate();

    // SETUP
    const currentLastVisibleItem = (titles !== undefined) ? titles.docs[titles.size - 1] : null;

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (titles !== undefined && titles.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (titles !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, titles]);

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, titles.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    // OTHER

    const gotoTitleDetail = (e, titleId) => {
        e.stopPropagation();

        // validate
        let docIdRegex = /^[A-Za-z0-9]{10,30}$/;
        if (!titleId || !docIdRegex.test(titleId)) {
            window.alert("Cannot view title: identifier is not set.");
            return;
        }

        // outta here
        navigate(`/app/title/${titleId}`);
    }

    return (
        <>
            {/* No titles; fallback, shouldn't ever happen */}
            {titles.docs.length === 0 && <div className="mt-6 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <div className="text-center">
                    <p className="text-lg leading-6 font-medium text-gray-900">No titles</p>
                    <p className="mt-2 text-sm text-gray-500">
                        <p>No titles found; they will appear here as they are loaded.</p>
                    </p>
                </div>
            </div>}

            {/* Titles list */}
            {titles.docs.length > 0 && <>
                {/* start A */}
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h1 className="my-0 text-2xl font-bold text-gray-900 sm:text-3xl">Titles List</h1>

                        {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Recently loaded titles are shown below:</p>*/}
                        {/*formLoading === true && <LoadingBanner />*/}
                        {/*errorBannerOpen === true && <ErrorBanner message={lastErrorMessage} />*/}
                    </div>

                    {titles.size > 0 && <div>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Released</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Runtime</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Director(s)</th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added</th>
                                </tr>
                            </thead>
                            <tbody>
                                {titles.docs.map((title, itemIdx) => (
                                    <tr key={title.id} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm text-gray-900">
                                            {title.data().kind === 1 && <img src="/images/Kind_movie.png" alt="" />}
                                            {title.data().kind === 2 && <img src="/images/Kind_tv.png" alt="" />}
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            <span className='hover:underline hover:cursor-pointer' onClick={(e) => { gotoTitleDetail(e, title.id) }}>{title.data().title}</span>
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            {title.data().featured !== undefined && title.data().featured === true && <img src="/images/Flag_featured.png" alt="" />}
                                        </td>
                                        <td className="px-2 py-4 text-sm text-gray-900">
                                            {title.data().release_date !== undefined && formatDateShort(title.data().release_date.toDate())}
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {(title.data().runtimeHH !== undefined && title.data().runtimeMM !== undefined && !isNaN(title.data().runtimeHH) && !isNaN(title.data().runtimeMM)) && <span>{`${title.data().runtimeHH}h ${title.data().runtimeMM}m`}</span>}
                                            {(title.data().runtimeHH === undefined || title.data().runtimeMM === undefined || isNaN(title.data().runtimeHH) || isNaN(title.data().runtimeMM)) && "—"}
                                        </td>
                                        <td className="px-2 py-4 text-sm text-gray-900">
                                            {title.data().directors !== undefined && Object.values(title.data().directors).map((director, index) => (
                                                <span key={index}>{director}<br /></span>
                                            ))}

                                            {title.data().directors === undefined && "—"}
                                        </td>
                                        <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {title.data().created !== undefined && formatDateShort(title.data().created.toDate())}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
                {/* end A */}

                {/* Button actions */}
                <nav className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{firstItemStack.length === 0 ? titles.docs.length : (firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button type='button' onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button type='button' onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button type='button' onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            </>}
        </>



    )
}