import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Formik, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup"

export default function SignInDialog({ closeHandler }) {
    // STATE
    const [open, setOpen] = useState(true)
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [isLoading, setIsLoading] = useState(undefined);

    // HOOKS
    const auth = getAuth();
    const navigate = useNavigate();

    // FORMIK
    const initialValues = { email: '', password: '' };
    const validationSchema = Yup.object({
        email: Yup.string("Enter a valid email address").email("Invalid").required("Required"),
        password: Yup.string("Enter a password").required("Required")
    });

    // UI STATE

    const closeDialog = () => {
        setOpen(false);
        closeHandler();
    }

    // HELPERS

    const formatError = (code) => {
        switch (code) {
            case "auth/user-not-found":
                return "The specified account does not exist";
            case "auth/wrong-password":
                return "Incorrect password";
            case "auth/user-disabled":
                return "The specified account is disabled";
            default:
                return `An error occurred (${code})`;
        }
    }

    // FORMS

    const handleSubmit = (form) => {
        setIsLoading(true);
        signInWithEmailAndPassword(auth, form.email, form.password)
            .then((userCredential) => {
                // const user = userCredential.user;
                setErrorMessage(undefined);
                navigate('/app');
            })
            .catch((error) => {
                console.log('Exception: ' + JSON.stringify(error));
                setErrorMessage(formatError(error.code));
                setIsLoading(false);
            });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                                    {({ handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
                                        <form className="space-y-6" onSubmit={handleSubmit}>
                                            <div>
                                                <h2 className="mb-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                                    Sign in to your account
                                                </h2>

                                                {isLoading && <div className="mb-3 block text-sm"><p className="font-medium text-gray-700">Loading...</p></div>}
                                                {errorMessage && <div className="mb-3 block text-sm"><p className="font-bold text-red-700">{errorMessage}</p></div>}

                                                <div className="relative -space-y-px rounded-md shadow-sm">
                                                    <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
                                                    <div>
                                                        <label htmlFor="email" className="sr-only">Email address</label>
                                                        <input id="email" name="email" type="text" onChange={handleChange} onBlur={handleBlur} autoComplete='off' required className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" />
                                                        <ErrorMessage name="email" component="span" className="text-red-700 italic" />
                                                    </div><div>
                                                        <label htmlFor="password" className="sr-only">Password</label>
                                                        <input id="password" name="password" type="password" onChange={handleChange} onBlur={handleBlur} autoComplete='off' required placeholder="Password" className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                                        <ErrorMessage name="password" component="span" className="text-red-700 italic" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button type="submit" disabled={!dirty || !isValid} className="disabled:opacity-50 disabled:bg-indigo-1000 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2">
                                                    Sign in
                                                </button>
                                                <button type="button" onClick={() => closeDialog()} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}